//
// Variables
// --------------------------------------------------


// MAIN ADMIN LAYOUT
@sidebar-color-bg:            #455562;
@sidebar-toggle-color:        #fff;
@sidebar-link-color:          @gray-lighter;
@sidebar-link-hover-color:    #fff;
@sidebar-link-hover-bg:       @brand-primary;
@sidebar-link-border-bottom:  transparent;
@main-content-padding:        20px;
@main-content-bg:             @gray-lighter;
@main-block-bg:               #fff;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

@gray-base:             #000;
@gray-darkest:          #232323;
@gray-darker:           #373737;
@gray-dark: #494949;
@gray:                  #909191;
@gray-light:            #b7b7b7;
@gray-lighter:          #e9e9e9;
@gray-lightest: #f4f4f4;

@brand-blue:		    #13B5EA;
@brand-dark-blue:       #174A7C;
@brand-gray:		    #455560;
@brand-red:		        #EB4B25;
@brand-green:           #9ad023;
@brand-orange:          #ffa500;

@brand-primary:         @brand-blue;
@brand-secondary:       @brand-dark-blue;
@brand-tertiary:        @brand-red;
@brand-quaternary:      @brand-gray;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
//@text-color:            #111;
@text-color:            darken(@brand-quaternary, 15%);

//** Global textual link color.
@link-color:            darken(@brand-primary, 10%);
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: underline;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-yantramanav: "Yantramanav", Helvetica, Arial, sans-serif;
@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-yantramanav;

@font-size-base:          14px;
@font-size-large:         ceil((@font-size-base * 1.25));
@font-size-small:         ceil((@font-size-base * 0.85));

@font-size-h1:            floor((@font-size-base * 2.6));
@font-size-h2:            floor((@font-size-base * 2.15));
@font-size-h3:            ceil((@font-size-base * 1.7));
@font-size-h4:            ceil((@font-size-base * 1.25));
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85));

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    500;
@headings-line-height:    1.1;
@headings-color:          inherit;


//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
@icon-font-path:          "../fonts/";
//** File name for all font files.
@icon-font-name:          "glyphicons-halflings-regular";
//** Element ID within SVG icon file.
@icon-font-svg-id:        "glyphicons_halflingsregular";


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

@padding-base-vertical:     6px;
@padding-base-horizontal:   12px;

@padding-large-vertical:    10px;
@padding-large-horizontal:  16px;

@padding-small-vertical:    5px;
@padding-small-horizontal:  10px;

@padding-xs-vertical:       1px;
@padding-xs-horizontal:     5px;

@line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
@line-height-small:         1.5;

@border-radius-base:        2px;
@border-radius-large:       5px;
@border-radius-small:       0;

//** Global color for active items (e.g., navs or dropdowns).
@component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@component-active-bg:       @brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
@caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
@caret-width-large:         5px;


//DASHBOARDS
@dashboard-bg:                @brand-primary;
@dashboard-bg-hover:          darken(@brand-primary, 10%);
@dashboard-padding:           20px 26px;
@dashboard-border-radius:     1px;
@dashboard-color:             #fff;
@dashboard-font-size:         @font-size-base + 2;
@dashboard-h4-color:          #fff;
@dashboard-h4-size:           @font-size-base + 6;
@dashboard-ico-size:          40px;
@dashboard-ico-size-sm:       30px;
@dashboard-ico-sm-line-height:40px;

//Customize Dashboard
//** add class "bg-1, bg-2 ..." beside class tile
@dashboard-bg-1:              @brand-blue;
@dashboard-bg-2:              @brand-dark-blue;
@dashboard-bg-3:              @brand-gray;
@dashboard-bg-4:              @brand-red;
@dashboard-bg-5:              @brand-green;
@dashboard-bg-6:              @brand-orange;
@dashboard-bg-neutral:        @gray-lighter;

@dashboard-bg-1-hover:        darken(@brand-blue, 10%);
@dashboard-bg-2-hover:        darken(@brand-dark-blue, 10%);
@dashboard-bg-3-hover:        darken(@brand-gray, 10%);
@dashboard-bg-4-hover:        darken(@brand-red, 10%);
@dashboard-bg-5-hover:        darken(@brand-green, 10%);
@dashboard-bg-6-hover:        darken(@brand-orange, 10%);
@dashboard-bg-neutral-hover:  darken(@gray-lighter, 10%);
//** add class "color-secondary" beside class tile
//** customize only color ico/subtitle and h4
@dashboard-h4-color-secondary:#101010;
@dashboard-color-secondary:   @gray-darker;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
@table-cell-padding:            8px;
//** Padding for cells in `.table-condensed`.
@table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
@table-bg:                      transparent;
//** Default color of fonts in tables
@table-font-color: #545454;
//** Background color used for `.table-striped`.
@table-bg-accent:               #f9f9f9;
//** Background color used for `.table-hover`.
@table-bg-hover:                transparent;
@table-bg-active:               @table-bg-hover;

//** Border color for table and cell borders.
@table-border-color:            darken(@gray-lighter, 8%);

//** Table action buttons
@table-function-buttons-font-size:    @font-size-base;
@table-function-buttons-padding:      5px;
@table-function-buttons-bg:           #455562;
@table-function-buttons-border:       0;
@table-function-buttons-color:        #fff;
@table-function-buttons-color-hover:  #fff;
@table-function-buttons-bg-hover:     darken(#455562, 10%);
@table-function-buttons-border-hover: 0;
@table-function-border-radius:        1px;
// Customize colors
@function-color-danger:               @brand-danger;
@function-color-success:              @brand-success;
@function-color-info:                 @brand-info;
@function-color-neutral:              @gray;
@function-color-white:                #fff;
@function-bg-color-danger:            @brand-danger;
@function-bg-color-success:           @brand-success;
@function-bg-color-info:              @brand-info;
@function-bg-color-neutral:           @gray;
@function-bg-color-white:             transparent;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@btn-font-weight:                normal;
@btn-border-radius:              @border-radius-base;

@btn-default-color:              #fff;
@btn-default-bg:                 @gray-dark;
@btn-default-border:             @gray-dark;

@btn-default-color-hover:        #fff;
@btn-default-bg-hover:           darken(@btn-default-bg, 10%);
@btn-default-border-hover:       darken(@btn-default-bg, 10%);

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             @brand-primary;

@btn-primary-color-hover:        #fff;
@btn-primary-bg-hover:           darken(@btn-primary-bg, 10%);
@btn-primary-border-hover:       darken(@btn-primary-bg, 10%);

@btn-secondary-color:            #fff;
@btn-secondary-bg:               @brand-secondary;
@btn-secondary-border:           @brand-secondary;

@btn-secondary-color-hover:      #fff;
@btn-secondary-bg-hover:         darken(@btn-secondary-bg, 10%);
@btn-secondary-border-hover:     darken(@btn-secondary-bg, 10%);

@btn-tertiary-color:            #fff;
@btn-tertiary-bg:               @brand-tertiary;
@btn-tertiary-border:           @brand-tertiary;

@btn-tertiary-color-hover:      #fff;
@btn-tertiary-bg-hover:         darken(@btn-tertiary-bg, 10%);
@btn-tertiary-border-hover:     darken(@btn-tertiary-bg, 10%);

@btn-black-color:               #fff;
@btn-black-bg:                  @gray-darkest;
@btn-black-border:              @gray-darkest;

@btn-black-color-hover:         #fff;
@btn-black-bg-hover:            #000;
@btn-black-border-hover:        #000;

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 10%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;


//== Forms
//
//##

//** `<input>` background color
@input-bg:                       #fff;
//** `<input disabled>` background color
@input-bg-disabled:              @gray-lighter;

//** Text color for `<input>`s
@input-color:                    @gray-dark;
//** `<input>` border color
@input-border:                   @gray-light;

// TODO: Rename `@input-border-radius` to `@input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
@input-border-radius:            @border-radius-base;
//** Large `.form-control` border radius
@input-border-radius-large:      @border-radius-large;
//** Small `.form-control` border radius
@input-border-radius-small:      @border-radius-small;

//** Border color for inputs on focus
@input-border-focus:             @brand-blue;

//** Placeholder text color
@input-color-placeholder:        @gray;

//** Default `.form-control` height
@input-height-base:              (@line-height-computed + (@padding-base-vertical * 2) + 4);
//** Large `.form-control` height
@input-height-large:             (ceil(@font-size-large * @line-height-large) + (@padding-large-vertical * 2) + 2);
//** Small `.form-control` height
@input-height-small:             (floor(@font-size-small * @line-height-small) + (@padding-small-vertical * 2) + 2);

@input-padding:                   6px 12px;

//** `.form-group` margin
@form-group-margin-bottom:       10px;

@legend-color:                   @gray-dark;
@legend-border-color:            #e5e5e5;

//** Background color for textual input addons
@input-group-addon-bg:           @gray-lighter;
//** Border color for textual input addons
@input-group-addon-border-color: @input-border;

//** Disabled cursor for form controls and buttons.
@cursor-disabled:                not-allowed;

//** RADIO BUTTONS GROUP
//** class btn-group
@btn-group-bg:                @gray-light;
@btn-group-border:            1px solid @gray-light;
@btn-group-color:             @gray-lighter;
@btn-group-bg-hover:          @gray-light;
@btn-group-border-hover:      1px solid @gray-light;
@btn-group-color-hover:       @gray-darker;
@btn-group-bg-active:         @gray-dark;
@btn-group-border-active:     1px solid @gray-dark;
@btn-group-color-active:      #fff;



//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
@dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
@dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
@dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
@dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
@dropdown-link-color:            @gray-darker;
//** Hover color for dropdown links.
@dropdown-link-hover-color:      @gray-lighter;
//** Hover background for dropdown links.
@dropdown-link-hover-bg:         #455562;

//** Active dropdown menu item text color.
@dropdown-link-active-color:     @component-active-color;
//** Active dropdown menu item background color.
@dropdown-link-active-bg:        @component-active-bg;

//** Disabled dropdown menu item background color.
@dropdown-link-disabled-color:   @gray-light;

//** Text color for headers within dropdown menus.
@dropdown-header-color:          @gray-light;

//** Deprecated `@dropdown-caret-color` as of v3.1.0
@dropdown-caret-color:           #000;


//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

@zindex-maincontent:       990;
@zindex-navbar:            1000;
@zindex-dropdown:          1000;
@zindex-navbar-fixed:      1030;
@zindex-modal-background:  1050;
@zindex-modal:             1060;
@zindex-popover:           1060;
@zindex-tooltip:           1070;
@zindex-sidebar:           1090;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:         30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     1024px;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet:             (720px + @grid-gutter-width);
//** For `@screen-sm-min` and up.
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            (940px + @grid-gutter-width);
//** For `@screen-md-min` and up.
@container-md:                 @container-desktop;

// Large screen / wide desktop
@container-large-desktop:      (1140px + @grid-gutter-width);
//** For `@screen-lg-min` and up.
@container-lg:                 @container-large-desktop;


//== Navbar
//
//##

// Basics of a navbar
@navbar-height:                    60px;
@navbar-margin-bottom:             0;
@navbar-border-radius:             @border-radius-base;
@navbar-padding-horizontal:        floor((@grid-gutter-width / 2));
@navbar-padding-vertical:          ((@navbar-height - @line-height-computed) / 2);
@navbar-collapse-max-height:       340px;

@navbar-default-color:             @gray-dark;
@navbar-default-bg:                #fff;
@navbar-default-border:            darken(@navbar-default-bg, 10%);

// Navbar links
@navbar-default-link-padding:              6px;
@navbar-default-link-padding-short:        6px 5px;
@navbar-default-link-color:                @gray;
@navbar-default-link-hover-color:          @gray-base;
@navbar-default-link-hover-bg:             transparent;
@navbar-default-link-active-color:         @gray-base;
@navbar-default-link-active-bg:            transparent;
@navbar-default-link-disabled-color:       #ccc;
@navbar-default-link-disabled-bg:          transparent;
@navbar-default-img-height:                36px;

// Navbar brand label
@navbar-default-brand-color:               @gray-light;
@navbar-default-brand-hover-color:         @gray-dark;
@navbar-default-brand-hover-bg:            transparent;
@navbar-default-brand-line-height:         46px;
@navbar-default-brand-padding:             @navbar-default-link-padding;
@navbar-default-brand-font-size:           @font-size-base + 10;

// Navbar toggle
@navbar-default-toggle-hover-bg:           #ddd;
@navbar-default-toggle-icon-bar-bg:        #888;
@navbar-default-toggle-border-color:       #ddd;



//== Navs
//
//##

//=== Shared nav styles
@nav-link-padding:                          10px 15px;
@nav-link-hover-bg:                         @gray-lighter;

@nav-disabled-link-color:                   @gray-light;
@nav-disabled-link-hover-color:             @gray-light;

//== Tabs
@nav-tabs-border-color:                     #ddd;

@nav-tabs-link-hover-border-color:          @gray-lighter;

@nav-tabs-active-link-hover-bg:             @body-bg;
@nav-tabs-active-link-hover-color:          @gray-darker;
@nav-tabs-active-link-hover-border-color:   #ddd;

@nav-tabs-justified-link-border-color:            #ddd;
@nav-tabs-justified-active-link-border-color:     @body-bg;

//== Pills
@nav-pills-border-radius:                   @border-radius-base;
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         @component-active-color;


//== Pagination
//
//##

@pagination-color:                     @gray-dark;
@pagination-bg:                        transparent;
@pagination-border:                    @table-border-color;

@pagination-hover-color:               #fff;
@pagination-hover-bg:                  lighten(@brand-gray, 25%);
@pagination-hover-border:              lighten(@brand-gray, 25%);

@pagination-active-color:              #fff;
@pagination-active-bg:                 lighten(@brand-gray, 25%);
@pagination-active-border:             lighten(@brand-gray, 25%);

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               @gray-lightest;
@pagination-disabled-border:           @gray-lightest;



//== Wells
//
//##

@well-bg:                     #f5f5f5;
@well-border:                 darken(@well-bg, 7%);



//== Login and Error
@panel-window-width: 450px;

@image-error:      "../../img/bg-snow.jpg";
@image-login:      "../../img/bg-lights.jpg";



