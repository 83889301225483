.navbar {
  border-radius: 0;

    .nav {

      &> li > a{
        padding: @navbar-default-link-padding;
        font-size: @font-size-base;
        
        &.lang{
          padding: @navbar-default-link-padding-short;
        }
      }
    }
}
.navbar-nav > li > a, .navbar-brand, .sidebar-toggle{
  line-height: @navbar-default-brand-line-height;
}
.navbar-brand{
  font-size: @navbar-default-brand-font-size;
  padding: @navbar-default-brand-padding;
  margin-left: 0 !important;
  float: right;
  
  img{
    height: @navbar-default-img-height;
    display: inline;
    vertical-align: middle;
  }
}
.nav-collapse{
  outline: none;
}
.toggleButWrapper{
  width: 100%;
}
.navbar-default .navbar-collapse, .navbar-default .navbar-form{
  border: 0;
}
.collapse.in{
  .navbar-nav{
    margin-top: 0;
    margin-bottom: 0;

    & > li > a{
      line-height: 1;
      text-align: right;
    }
  }

  .navbar-nav .open .dropdown-menu > li > a{
    text-align: right;
  }
}
.navbar-default .navbar-toggle {
  border: 0;
}

.navbar-default{
  .logo-wrapper{
    text-align: right;
  }



  .navbar-toggle{
    margin: 12px 0;

    &:hover, &:active, &:focus{
      background-color: transparent;
      .icon-bar{
        background-color: @gray;
      }
    }
    .icon-bar {
      background-color: @gray-light;
      border-radius: 1px;
      display: block;
      height: 3px;
      width: 28px;
    }
  }

  .collapse.in{
    .navbar-lang{
      li{
        display: inline-block;
      }
    }
  }

  .dropdown{

    .dropdown-toggle{
      vertical-align: middle;
      margin-left: 20px;

      &:after{
        content: "\f107";
        font-family: 'FontAwesome';
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        font-size: @font-size-base + 2;
      }
    }

    .dropdown-menu{
      box-shadow: 0 3px 4px rgba(0, 0, 0, 0.176);

      .dropdown-ico{
        margin: 0 3px 0 7px;
        color: lighten(@dropdown-link-color, 30%);

        &:hover{
          color: @dropdown-link-hover-color;
        }
      }

      & > li > a{
        padding: 3px;
        line-height: 2;
        font-size: @font-size-base;
        font-weight: 300;
        text-align: right;
      }
    }

    &.open{
      .dropdown-toggle{

        &:after{
          content: "\f106";
        }
      }
    }
  }
}

