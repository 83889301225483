/*BACKWARD COMPATIBILITY*/
.layout-v0 {
  height: 100%;
  body {
    height: 100%;
  }
  .page-container {
    border-collapse: collapse;
    border-spacing: 0;
    height: 100%;
    width: 100%;
    display: table;
    table-layout: fixed;

    .container-fluid.main-layout {
      min-height: 100%;
      font-family: @font-family-base;
      font-weight: 300;
    }

    .sidebar {
      display: table-cell;
      width: 240px;
      position: fixed;
      z-index: @zindex-sidebar;
      min-height: 100%;
      background-color: @sidebar-color-bg;
      vertical-align: top;

      .nav-sidebar {
        position: fixed;
        width: 240px;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-y: auto;
      }

      &.sidebar-hidden {
        position: relative;
        width: 80px;
        .nav-sidebar {
          position: absolute;
          width: 80px;
          overflow: visible;
        }
      }

    }

    .main-layout {
      display: table;
      min-width: 100%;
      position: relative;
      padding-left: 0;

      .main-content {
        width: 100%;
        height: auto;
        min-height: 100%;
        background-color: @main-content-bg;
        padding: @main-content-padding @main-content-padding @main-content-padding 240px + @main-content-padding;
        transition: opacity 300ms ease 0s;
        z-index: @zindex-maincontent;

        .main-block {
          background-color: @main-block-bg;
          padding: 20px;

          &.table-layout {
            padding: 0;
            border: 0;
          }
        }

        &.width-tables {
          display: inline-flex;
        }
      }

      .navbar {
        padding-left: 240px;
      }

      &.collapsed-sidebar {
        .main-content {
          padding: @main-content-padding;
        }
        .navbar {
          padding-left: 0;
        }
      }
    }
  }
}