/* DEFAULT STYLE FOR ALL TABLES */
.table {
  margin-bottom: 20px;

}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td{
  vertical-align: middle;
  background-clip: padding-box;
  white-space: normal;
}
.table > tbody > tr > td{
  color: @table-font-color;
}
.table.table.no-border > thead > tr > th,
.table.no-border > tbody > tr > th,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > tbody > tr > td,
.table.no-border > tfoot > tr > td{
  border-top: 0;
}
.table.table-bordered > thead > tr > th,
.table.table-bordered > tfoot > tr > td,
.table.table-bordered > tbody > tr > td{
  border: 1px solid @table-border-color;
}
.table tbody,
.table > thead.filters > tr{
  border-top: 2px solid darken(@table-border-color, 3%);
}
.table > thead > tr > th {
  border-bottom: 0;
  vertical-align: bottom;
}

.table > tfoot > tr > td{
  border-bottom: 0;
  background-color: transparent;
  color: @gray-base;
  padding: 15px 5px;
}
.table > tfoot > tr > td{
  font-weight: 400;
}
.table > tfoot > tr > td{
  border-bottom: 1px solid @table-border-color;
}
/* ----------------------------------------------------------------------------- */

/* CUSTOMIZE BOOTSTRAP TABLE CLASSES */

.table-hover > tbody > tr:hover td{
  color: @gray-base;
}

th{
  .order-first:after{
    font-family: fontawesome;
    content: @fa-var-caret-down;
    display: inline-block;
    margin-left: 5px;
  }
  .order-last:after{
    font-family: fontawesome;
    content: @fa-var-caret-up;
    display: inline-block;
    margin-left: 5px;
  }
}
/* ----------------------------------------------------------------------------- */

.table{

  /* ----------------------------------------------------------------------------- */

  /* CUSTOMIZE CELLS ALA ELLIPSIS */

  td, th{
    &.ellipsis{
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.width-xs{
        max-width: 50px;
      }
      &.width-sm{
        max-width: 100px;
      }
      &.width-md{
        max-width: 200px;
      }
      &.width-lg{
        max-width: 300px;
      }
    }

    &.width-xs{
      width: 50px;
    }
    &.width-sm{
      width: 100px;
    }
    &.width-md{
      width: 200px;
    }
    &.width-lg{
      width: 300px;
    }
  }

  .ellipsis{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &.width-xs{
      max-width: 50px;
    }
    &.width-sm{
      max-width: 100px;
    }
    &.width-md{
      max-width: 200px;
    }
    &.width-lg{
      max-width: 300px;
    }
  }

  /* ----------------------------------------------------------------------------- */

  /* FILTERS STYLE */

  .filters{

    & > tr {

      & > th{
        border-bottom: 0;
        background-color: transparent;
        color: @gray-base;
        border-top: 0;
        padding: 5px;
      }
    }

    & > tr > th input,
    & > tr > th select,
    & > tr > th .select2-container--default .select2-selection--single{
      border: 1px solid darken(@gray-lighter, 10%);
      border-radius: 1px;
      background-color: #fff;
      padding: 3px 5px;
      height: 30px;

      &:focus{
        border: 1px solid @brand-primary;
      }
    }

    .btn{
      padding: 1px 5px;
    }

    /* STYLES FOR SELECT2 */
    .select2-container--default{

      .select2-selection--multiple{
        height: 30px;

        .select2-selection__rendered {
          padding: 0;
        }

        .select2-selection__choice {
          margin-right: 3px;
          margin-top: 0;
        }

      }

    }
    .select2-container{
      width: auto !important;

      .select2-container--default,
      .select2-selection--multiple{
        border: 1px solid @input-border;
        border-radius: 1px;
        padding: 2px 3px;
        height: 28px;
        min-height: 28px;
      }

      .select2-search--inline .select2-search__field{
        margin-top: 0;
      }

    }

    & > tr > th input.select2-search__field{
      height: 22px;
    }

  }
}
/* ----------------------------------------------------------------------------- */

/* CUSTOMIZE TABLES FUNCTIONS BUTTON */

.functions{
  text-align: right;
  white-space: nowrap!important;

  .btn-function{
    font-size: @table-function-buttons-font-size;
    padding: @table-function-buttons-padding;
    border-radius: @table-function-border-radius;
    background-color: @table-function-buttons-bg;
    border: @table-function-buttons-border;
    color: @table-function-buttons-color;
    line-height: 1;
    text-decoration: none;

    &:hover{
      color: @table-function-buttons-color-hover;
      border: @table-function-buttons-border-hover;
      background-color: @table-function-buttons-bg-hover;
    }

    &.color-danger{
      color: @function-color-danger;
      &:hover{
        color: darken(@function-color-danger, 15%)
      }
    }

    &.color-success{
      color: @function-color-success;
      &:hover{
        color: darken(@function-color-success, 15%)
      }
    }

    &.color-info{
      color: @function-color-info;
      &:hover{
        color: darken(@function-color-info, 15%)
      }
    }

    &.color-neutral{
      color: @function-color-neutral;
      &:hover{
        color: darken(@function-color-neutral, 15%)
      }
    }

    &.color-white{
      color: @function-color-white;
      &:hover{
        color: darken(@function-color-white, 15%)
      }
    }

    &.bg-color-danger{
      background-color: @function-bg-color-danger;
      &:hover{
        background-color: darken(@function-bg-color-danger, 15%)
      }
    }

    &.bg-color-success{
      background-color: @function-bg-color-success;
      &:hover{
        background-color: darken(@function-bg-color-success, 15%)
      }
    }

    &.bg-color-info{
      background-color: @function-bg-color-info;
      &:hover{
        background-color: darken(@function-bg-color-info, 15%)
      }
    }

    &.bg-color-neutral{
      background-color: @function-bg-color-neutral;
      &:hover{
        background-color: darken(@function-bg-color-neutral, 15%)
      }
    }
    &.bg-color-white{
      background-color: @function-bg-color-white;
      &:hover{
        background-color: darken(@function-bg-color-white, 15%)
      }
    }
  }

}
/* ----------------------------------------------------------------------------- */
/* TABLE COLLAPSING SETTINGS (FILTER etc.) */

.table-settings{
  position: relative;
  background-color: @main-content-bg;

  .table-title{
    float: left;
    font-weight: 600;
    padding: 5px 0;
  }

  .filter-wrapper{
    padding: 10px;
    border-bottom: 2px solid @table-border-color;
    border-top: 2px solid @table-border-color;

    .form-control,
    .select2-container--default .select2-selection--single{
      border: 1px solid darken(@gray-lighter, 10%);
      &:focus{
        border: 1px solid @brand-primary;
      }
    }

    .btn {
      margin-top: 25px;
    }
  }
}

/* FORMS ITEMS in TABLE */
.table{
  .form-group{
    margin-bottom: 0;
  }
  .radio, .checkbox {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 23px;

    label{
      padding-left: 0;
    }
  }
}