/*==========================================*/
/*===============   GALLERY  ===============*/
/*==========================================*/

.thumb {
  height: 230px;
  position: relative;
  background: #8394a3;
  overflow: hidden;
  margin: 0 auto 30px;
  img {
    width: auto;
    height: 100%
  }

  &-label {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #e74c3c;
    text-transform: uppercase;
    padding: 1px 10px 0;
    color: #fff;
    font-size: 16px;
    font-family: Bebas, Arial;
    letter-spacing: 1px;
    line-height: 1.2;
    z-index: 1005
  }

  &-perex {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px 20% 10px 10px;
    color: #fff;
    font-size: 18px;
    z-index: 1005
  }

  &-big {
    height: 490px;
    .thumb-perex {
      font-size: 28px
    }
  }

  &-icon {
    position: absolute;
    width: 45px;
    height: 45px;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1005;
    border: 0;

    .lnr {
      display: inline-block;
      font-size: 48px;
      color: #fff
    }

    .lnr:hover {
      color: #d9d9d9
    }
  }

  &-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1001;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4yIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, .5)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#33000000', GradientType=0)
  }
}

/*==========================================*/
/*===============   TILES   ================*/
/*==========================================*/

.tile {
  text-align: left;
  padding: @dashboard-padding;
  display: block;
  margin-bottom: 30px;
  background-color: @dashboard-bg;
  outline: none;
  border-radius: @dashboard-border-radius;
  transition: background-color 200ms;
  position: relative;
  overflow: hidden;

  &:hover {
    text-decoration: none;
    background-color: darken(@brand-primary, 10.5%);
  }

  h4 {
    color: @dashboard-h4-color;
    margin: 0;
    font-weight: 300;
    font-size: @dashboard-h4-size;
    line-height: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.tile-percent {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .tile-description {
    color: rgba(255, 255, 255, 0.8);
    font-size: @dashboard-font-size;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-ico-container {
    display: flex;
    align-items: center; /* Vertical center alignment */
  }

  &-ico-bg {
    position: absolute;
    left: 61%;
    top: -14%;
    zoom: 1;
    opacity: 0.1;
    .tile-ico {
      color: #fff !important;
      line-height: 1;
      display: block;
      font-size: 150px !important;
    }
  }

  .tile-ico {
    font-size: @dashboard-ico-size;
    color: @dashboard-color;

    &.sm {
      font-size: @dashboard-ico-size-sm;
      line-height: @dashboard-ico-sm-line-height;
    }
  }

  &.title-color-sec {
    h4 {
      color: @dashboard-h4-color-secondary;
    }
  }
  &.ico-color-sec {
    .tile-description, .tile-ico {
      color: @dashboard-color-secondary;
    }
  }

  .tile-ico-container {
    width: 58px;
    height: 58px;
    .tile-ico {
      position: relative;
      right: 5px;
    }
  }

  //TILE THEMES AND BACKGROUND

  &-bordered {
    background-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: @brand-primary;

    h4, .tile-description {
      color: @brand-primary;
    }

    .tile-ico-container {
      background: @brand-primary;
      border-radius: 50%;

    }
    .tile-ico {
      position: relative;
      right: 5px;
    }

    &.tile-bg-1 {
      border-color: @dashboard-bg-1;
      h4, .tile-description {
        color: @dashboard-bg-1;
      }
      .tile-ico-container, .progress-bar {
        background-color: @dashboard-bg-1;
      }
    }

    &.tile-bg-2 {
      border-color: @dashboard-bg-2;
      h4, .tile-description {
        color: @dashboard-bg-2;
      }
      .tile-ico-container, .progress-bar {
        background-color: @dashboard-bg-2;
      }
    }

    &.tile-bg-3 {
      border-color: @dashboard-bg-3;
      h4, .tile-description {
        color: @dashboard-bg-3;
      }
      .tile-ico-container, .progress-bar {
        background-color: @dashboard-bg-3;
      }
    }

    &.tile-bg-4 {
      border-color: @dashboard-bg-4;
      h4, .tile-description {
        color: @dashboard-bg-4;
      }
      .tile-ico-container, .progress-bar {
        background-color: @dashboard-bg-4;
      }
    }

    &.tile-bg-5 {
      border-color: @dashboard-bg-5;
      h4, .tile-description {
        color: @dashboard-bg-5;
      }
      .tile-ico-container, .progress-bar {
        background-color: @dashboard-bg-5;
      }
    }

    &.tile-bg-6 {
      border-color: @dashboard-bg-6;
      h4, .tile-description {
        color: @dashboard-bg-6;
      }
      .tile-ico-container, .progress-bar {
        background-color: @dashboard-bg-6;
      }
    }

    &.tile-bg-neutral {
      background-color: @dashboard-bg-neutral;
      &:hover {
        background-color: @dashboard-bg-neutral-hover;
      }
    }

  }
  &-rounded {
    .tile-ico-container {
      background: #fff;
      border-radius: 50%;
      width: 58px;
      height: 58px;
    }
    .tile-ico {
      color: @dashboard-bg-1;
    }

    &.tile-bg-1 {
      .tile-ico {
        color: @dashboard-bg-1;
      }
    }

    &.tile-bg-2 {
      .tile-ico {
        color: @dashboard-bg-2;
      }
    }

    &.tile-bg-3 {
      .tile-ico {
        color: @dashboard-bg-3;
      }
    }

    &.tile-bg-4 {
      .tile-ico {
        color: @dashboard-bg-4;
      }
    }

    &.tile-bg-5 {
      .tile-ico {
        color: @dashboard-bg-5;
      }
    }
    &.tile-bg-6 {
      .tile-ico {
        color: @dashboard-bg-5;
      }
    }

    &.tile-bg-neutral {
      .tile-ico {
        color: @dashboard-bg-neutral;
      }
    }
  }

  &-bg-2 {
    background-color: @dashboard-bg-2;
    &:hover {
      background-color: @dashboard-bg-2-hover;
    }
  }

  &-bg-3 {
    background-color: @dashboard-bg-3;
    &:hover {
      background-color: @dashboard-bg-3-hover;
    }
  }

  &-bg-4 {
    background-color: @dashboard-bg-4;
    &:hover {
      background-color: @dashboard-bg-4-hover;
    }
  }

  &-bg-5 {
    background-color: @dashboard-bg-5;
    &:hover {
      background-color: @dashboard-bg-5-hover;
    }
  }

  &-bg-neutral {
    background-color: @dashboard-bg-neutral;
    &:hover {
      background-color: @dashboard-bg-neutral-hover;
    }
  }
}

/*==========================================*/
/*=============  PAGINATIONS  ==============*/
/*==========================================*/

.pagination {
  margin: 0;
  padding: 0 0 20px 0;

  li > a, li > span {
    padding: 8px 15px;
    margin-left: 3px;
    border-radius: 2px;
  }

  li.space > span {
    border: 0;
    background-color: transparent;

    &:hover {
      color: inherit;
    }
  }
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
}

/*==========================================*/
/*===============   TABLES  ===============*/
/*==========================================*/

@media (max-width: @screen-sm-min) {
  .table-responsive-list{
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    tr {
      border: 1px solid #ccc;
    }
    td {
      border: none !important;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
      white-space: normal;
      text-align: left;
      &:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: right;
        font-weight: bold;
        content: attr(data-title);
      }
    }
  }
}

@media (max-width: 767px) {
  .table-labels .label {
    display: block;
  }
}

.table-wide-container {
  overflow-x: scroll;
  overflow-y: hidden;

  .table > thead > tr > th,
  .table > tbody > tr > th,
  .table > tfoot > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > td,
  .table > tfoot > tr > td{
    white-space: nowrap;
  }

}

/*==========================================*/
/*============   FORM ELEMENTS  ============*/
/*==========================================*/

/*NUMBER INPUT*/
.spinner-number-input {
  .ui-spinner {
    display: flex;
    &-input {
      order: 2;
      .form-control;
      font-size: 20px;
      text-align: center;
      height: 100%;
      border-radius: 0;
    }
    &-down, &-up {
      .btn;
      .btn-primary;
      .btn-sm;
      line-height: 32px;
      margin: 0;
    }
    &-up {
      order: 1;
      border-radius: 2px 0 0 2px !important;
    }
    &-down {
      order: 3;
      border-radius: 0 2px 2px 0 !important;;
    }
  }
}

/*SELECT 2*/
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: @brand-primary;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid @brand-primary;
}

.select2-container--default .select2-selection--single .select2-selection__rendered{
  font-weight: normal;
}

/*MODAL*/
.modal-actions:before, .modal-actions:after {
  content: " ";
  display: table;
}

.modal-actions:after {
  clear: both;
}

.modal-action {
  float: left;
  width: 50%;
  padding: 15px;
  border: solid #e5e5e5;
  border-width: 1px 0 0;
}

.modal-action:hover {
  color: #095a74;
  background-color: transparent;
  border-color: #e5e5e5 !important;
  text-decoration: none;
}

.modal-action:first-child {
  border-bottom-left-radius: 5px;
}

.modal-action + .modal-action {

  border-left-width: 1px;

}


/*PROFILE IMG*/
.profile-img {
  display: block;
  //display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  border: 1px solid @input-border;
  &-sm {
    width: 40px;
    height: 40px;
  }
  &-lg {
    width: 200px;
    height: 200px;
  }
}

/*SCROLL TO TOP BTN*/
.btn-scroll-up {
  border-width: 0;
  z-index: 99;
  &:hover, &:focus {
    color: @brand-primary;
  }
}

/*USER PROFILE*/
.user-profile {
  padding: 20px;

  .control-label {
    font-weight: inherit;
  }
  .form-control-static {
    display: inline-block;
    vertical-align: middle;
    font-size: @font-size-base + 4;
  }
}

//DETAIL
.detail-block {
  line-height: 32px;
  font-size: 16px;
  h3 {
    color: @brand-primary;
    margin: 24px 0 6px;
    border-bottom: 1px solid lighten(@gray-light, 5%);
  }

  h4 {
    text-transform: uppercase;
    color: @gray;
    font-weight: normal;
    margin: 18px 0 5px 0;
    font-size: 14px;
  }
  a .lnr{
    vertical-align: middle;
  }
}

/*PAGE HEADER ACTIONS*/
.page-heading {
  //clearing floats
  overflow: auto;
  width: 100%;

  padding: 10px 0 20px 0;
  border-bottom: 0;

  h1 {
    color: @brand-primary;
  }

  h1, h2, h3, h4, h5 {
    float: left;
    margin: 0;
    line-height: 48px;
  }

  a {
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  &-actions {
    float: right;
  }
}



