//ASSIGN COLOR THEME
h1, h2, h3 {
  margin: 10px 0 20px;
  font-weight: 300;
}

strong {
  font-weight: 400;
}

html, body {
  background-color: @main-content-bg;
  font-family: @font-family-base;
  font-weight: 300;
}

.page-content {
  background: @main-block-bg;
}

aside,
aside:before {
  background-color: @brand-quaternary;
}

aside .nav .open > a:focus {
  background-color: lighten(@sidebar-color-bg, 5%);
}

.footer-content {
  border-top: 1px solid #E5E5E5;
  background: #fff;
}

li.has-children.open ul {
  background-color: lighten(@sidebar-color-bg, 5%);
}

.breadcrumbs {
  border-bottom: 1px solid #e5e5e5;
  background-color: #f5f5f5;
}

::selection {
  background: @brand-primary;
  color: #fff
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: @main-content-bg;
  border-left: 1px solid #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: @gray-light;
}

::-webkit-scrollbar-thumb:hover {
  background-color: @gray-light;
}
