@header-height: 60px;
@footer-height: 60px;

@sidebar-lg: 190px;
@sidebar-md: 105px;
@sidebar-sm: 43px;

@container-min-width: 360px;

@screen-huge-min: 1600;
//@screen-lg-min = 1023
//@screen-sm-max = 991
//@screen-sm-min = 768
//@screen-xs-max = 768
//@screen-xs-min = 480

//ZINDEXES
@zi-main: -2;
@zi-header: 120;
@zi-aside: 3;
@zi-aside-before: -1;
@zi-aside-list: 4;
@zi-footer-fixed: 5;
@zi-breadcrumbs: 1028;
@zi-blank-image-overlay: -1;

/*==========================================*/
/*==============   LAYOUT BASE =============*/
/*==========================================*/

html, body {
  padding: 0;
  margin: 0;
  min-height: 100%;
}

html {
  position: relative;
  height: auto;
  background: transparent;
  min-width: @container-min-width;
}

body {
  height: auto;
}

html, body, article {
  min-height: 100%;
}

/********************/
/****** MAIN *****/
/********************/

main {
  position: static;
  padding: 0;
}

main:before {
  display: block;
  content: "";
  position: absolute;
  z-index: @zi-main;
  width: 100%;
  max-width: inherit;
  bottom: 0;
  top: 0;
}

article {
  min-height: 100%;
  margin: 0;
  margin-left: @sidebar-lg;
  padding: 0;
}

.article-container {
  float: left;
  width: 100%;
}

.page-content {

  padding: 12px 3% 24px;
  margin: 3%;
  margin-bottom: 80px !important;
  box-shadow: 0px 1px 2px rgba(50, 50, 50, 0.05);
  overflow: hidden;
}

.page-content.page-content-responsive {
  padding: 12px;
  margin: 0;

  @media (min-width: @screen-sm-min) {
    margin: 16px 32px;
  }

  @media (min-width: @screen-md-min) {
    margin: 16px 100px;
  }

  @media (min-width: @screen-lg-min) {
    margin: 16px 210px;
  }

  @media (min-width: @screen-huge-min) {
    margin: 16px 320px;
  }
}

/*==========================================*/
/*==============  LAYOUT ADMIN  ============*/
/*==========================================*/

/*=== HEADER ===*/

header {
  min-height: @header-height;
  position: relative;
  z-index: @zi-header;
}

.navbar-fixed-top {
  + main {
    padding-top: @header-height;
  }
}

.nav {
  margin: 0;
}

.nav > li {
  position: relative;
  display: block;
}

@media (max-width: @screen-sm-min) {
  .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-collapse .dropdown-toggle:after {
    display: none !important;
  }
}

//revert user header dropdown
@media (max-width: @screen-lg-min) {
  .navbar-nav .dropdown.open .dropdown-menu {
    position: absolute;
    float: right;
    left: auto;
    right: 0;

    background-color: #fff;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.176);
    border-top: 0;
  }
}

/********************/
/****** SIDEBAR *****/
/********************/

aside {
  width: @sidebar-lg;
  height: 100%; //because of user sin sidebar whe header hidden
  position: relative;
  border-width: 0 1px 0 0;
  position: fixed;
  top: auto;
  z-index: @zi-aside;
}

aside:before {
  content: "";
  display: block;
  width: @sidebar-lg;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: @zi-aside-before;
}

//default responsivity = user control sidebar
@media only screen and (max-width: @screen-md-min) {
  aside {
    display: none;
    width: 0;
    bottom: auto;
    left: @sidebar-lg;
    margin-left: -@sidebar-lg;
  }

  aside + article {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  aside ~ footer .footer-content {
    margin-left: 0px !important;
  }

  aside.display {
    display: block;
    width: @sidebar-lg;
  }

  aside.display:before + article {
    margin-left: 0 !important;
    &:before + article {
      margin-left: 0 !important;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .navbar-toggle {
    display: block;
  }
}

@media (min-width: @screen-md-min) {
  .navbar-toggle {
    display: none;
  }
}

aside.sidebar-responsive {
  display: block;
  height: 100%;
  .nav-sidebar-user {
    position: absolute;
    bottom: 0;
  }
  @media (max-width: @screen-md-min) {
    width: @sidebar-md;
    &:before {
      width: @sidebar-md;
    }

    + article {
      margin-left: @sidebar-md !important;
    }

    .sidebar-label {
      display: block;
    }

    ul li a {
      text-align: center;
      padding: 13px 17px 13px 17px
    }

    li.has-children:after {
      display: none;
    }

    li.has-children.open ul {
      width: 240px;
      list-style-type: none;
      position: absolute;
      top: 0;
      left: 100%;
      padding: 0;
      z-index: @zi-aside-list;

      li a {
        padding: 5px 5px 5px 10px;
        text-align: left;
      }
    }
  }

  @media (max-width: @screen-sm-min) {
    width: @sidebar-sm;

    &:before {
      width: @sidebar-sm;
    }

    + article {
      margin-left: @sidebar-sm !important;
    }

    .sidebar-label {
      display: none;
    }

    ul li a {
      padding: 9px;
    }
  }

  @sidebar_height: 600px;
  @media (max-width: @screen-md-min) and (max-height: @sidebar_height) {
    position: relative;
    float: left;
    .nav-sidebar-user {
      position: static;
    }

  }
}

/*SIDEBAR ONLY LAYOUT*/
aside .nav {
  width: 100%;
  background-color: @sidebar-color-bg;
}

aside .dropdown-menu {
  width: 100%;
  background-color: lighten(@sidebar-color-bg, 5%);
  border: 0;
  box-shadow: none;
  margin-bottom: 0 !important;
}

@media (max-width: @screen-sm-min) {
  aside .profile-img {
    display: inline-block;
    width: 26px !important;
    height: 26px;
  }
}

/*****************************/
/********** FOOTER ***********/
/****************************/

footer {
  height: 0;
  width: 0;
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: @footer-height;
  z-index: @zi-footer-fixed;
  .footer-content {
    margin-left: 0 !important;
  }
}

.footer-content {
  position: absolute;
  margin-left: @sidebar-lg;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 12px;
  line-height: 36px;
  text-align: center;
}

footer .footer-content {
  @media (max-width: @screen-md-min) {
    margin-left: @sidebar-md;
  }
  @media (max-width: @screen-sm-min) {
    margin-left: @sidebar-sm;
  }
}

/*****************************/
/****** BREADCRUMBS NAV *****/
/****************************/

.breadcrumbs {
  position: relative;
  min-height: 41px;
  line-height: 40px;
  padding: 0 12px 0 0;
  display: block;
}

.breadcrumb {
  margin-bottom: 0;
}

.breadcrumbs.breadcrumbs-fixed {
  position: fixed;
  right: 0;
  left: @sidebar-lg;
  top: @header-height;
  z-index: @zi-breadcrumbs;
}

@media (min-width: @screen-md-min) {
  .breadcrumbs-fixed + .page-content {
    margin-top: @header-height+14;
  }
}

/*TABBED LAYOUT*/
.content-tabs {
  .page-content {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .tab-content {
    background: #fff;
  }

  .nav-tabs li {
    width: 25%;
    text-align: center;
  }
}

/*==========================================*/
/*==============   LAYOUT FRONT =============*/
/*==========================================*/

.layout-front {
  min-height: 100%;
  position: relative;
  padding: 0;

  .container-fluid {
    padding: 0;
  }

  section {
    padding: 110px 5px 130px;
    position: relative;
  }

  .front-section-blue {
    background: @brand-secondary;
  }
}

/*==========================================*/
/*==============   LAYOUT BLANK =============*/
/*==========================================*/
.layout-blank {
  height: 100%;
  width: 100%;
  position: fixed;

  &:before {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;

    content: "";
    left: 0;
    right: 0;
    z-index: @zi-blank-image-overlay;
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    animation: blur 30s infinite;
  }

  .panel {
    text-align: center;
    top: 0;
    right: 0;
    left: 0;
    margin: 100px auto;
    width: @panel-window-width;
    padding: 40px 55px;
    background: rgba(255, 255, 255, 0.88);

    img {
      display: inline !important;
    }
  }

  h1 {
    color: @brand-primary;
    font-size: 100px;
    font-weight: bold;
  }
  h2 {
    color: @brand-primary;
    font-size: 32px;
  }
  p {
    font-size: 16px;
    color: @brand-primary;
  }
  .form-group-remember {
    text-align: left;
    padding: 4px 0;
  }
  @media (max-width: @screen-sm-min) {
    .panel {
      margin: 0;
      width: 100%;
    }
  }
}

.body-error {
  .layout-blank {
    &:before {
      background-image: url(@image-error);
    }
  }
}

.body-auth {
  .layout-blank {
    &:before {
      background-image: url(@image-login);
    }

    .panel-heading {
      margin-bottom: 35px;
    }
  }
}

/*==========================================*/
/*======== ANIMATIONS FOR LAYOUT  ==========*/
/*==========================================*/
aside,
aside:before {
  transition: width 0.1s linear;
}

article, .footer-content {
  transition: margin-left 0.1s linear;
}

a:hover {
  transition: all 200ms;
}

.dropdown, .dropup {
  .dropdown-menu {
    transition: all 0.3s;
    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
  }
  &.open .dropdown-menu {
    max-height: 500px;
    opacity: 1;
  }
}

.has-children {
  ul {
    transition: all 0.3s;
    max-height: 0;
    display: block !important;
    overflow: hidden;
    opacity: 0;
  }
  &.open ul {
    max-height: 500px;
    opacity: 1;
  }
}