.dropdown-menu > li > .lang {
  display: inline-block;
  width: 50%;
  text-align: center !important;
}

.dropdown-toggle .profile-img{
  display: inline-block;
}

.lnr {
  font-size: 20px;
  vertical-align: middle;
}
a:hover .lnr{
  text-decoration: none!important;
}

#chart_div {
  height: 400px;
}

//BOOTSTRAP MODIFICATION OVERDRIVES
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.dropdown-menu {
  border-radius: 1px;
  padding: 5px;
  border: 0;
  margin: 0;
}

.nav-tabs {
  li > a {
    background-color: @gray-lighter;
    border-radius: 0;
    color: @gray;
    outline: none;
  }

  li.active > a {
    background-color: #fff;
    color: @gray-darkest;
  }

  li > a:hover {
    color: @gray-darkest;
  }
}


@media (min-width: 768px) {
  .form-horizontal {
    .control-label {
      text-align: right;
    }
  }
}

.tab-content {
  padding: 20px;
}


/*==========================================*/
/*===============  BUTTONS  ================*/
/*==========================================*/
.btn {
  border-radius: @btn-border-radius;
  text-transform: uppercase;
  transition: background-color 200ms;
  line-height: 24px;
  padding: 5px 12px;

  font-size: @font-size-base + 4;
  font-weight: 300;

  &.btn-sm {
    padding: 4px 8px;
    font-size: @font-size-base + 1;
  }

  &.btn-lg {
    padding: 10px 14px;
    font-size: @font-size-base + 8;
  }

  &.btn-primary {
    color: @btn-primary-color;
    border: 1px solid @btn-primary-border;
    background-color: @btn-primary-bg;
  }
  &.btn-primary:hover,
  &.btn-primary:focus {
    color: @btn-primary-color-hover;
    border: 1px solid @btn-primary-border-hover;
    background-color: @btn-primary-bg-hover;
  }
  &.btn-secondary {
    color: @btn-secondary-color;
    border: 1px solid @btn-secondary-border;
    background-color: @btn-secondary-bg;
  }
  &.btn-secondary:hover,
  &.btn-secondary:focus {
    color: @btn-secondary-color-hover;
    border: 1px solid @btn-secondary-border-hover;
    background-color: @btn-secondary-bg-hover;
  }
  &.btn-tertiary {
    border: 1px solid @brand-tertiary;
    background-color: @brand-tertiary;
    color: @btn-tertiary-color;
  }
  &.btn-tertiary:hover,
  &.btn-tertiary:focus {
    background-color: darken(@brand-tertiary, 10%);
    color: @btn-tertiary-color;
  }

  &.btn-default {
    border: 1px solid lighten(@brand-gray, 25%);
    background-color: lighten(@brand-gray, 25%);
  }
  &.btn-default:hover,
  &.btn-default:focus {
    background-color: lighten(@brand-gray, 15%);
    border: 1px solid lighten(@brand-gray, 15%);
    color: #fff;
  }

  &.btn-black {
    color: @btn-black-color;
    border: 1px solid @btn-black-border;
    background-color: @btn-black-bg;
  }
  &.btn-black:hover,
  &.btn-black:focus {
    color: @btn-black-color-hover;
    border: 1px solid @btn-black-border-hover;
    background-color: @btn-black-bg-hover;
  }

  .lnr {
    font-size: @font-size-base + 1;
  }

  .caret {
    margin-left: 5px;
  }
}

//CUSTOM DES BUTTONS
.btn .lnr {
  display: inline-block; //icon animation fix
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
}

.functions {
  .btn {
    padding: 0 5px;
    .lnr {
      font-size: 14px;
      line-height: 13px;
    }
  }
}

.btn-add {
  .btn.btn-primary();
  &:hover .lnr {
    animation: pulse 2.5s ease infinite;

  }
}

.btn-detail {
  .btn-primary();
  &:hover .lnr {
    animation: wrench 2.5s ease infinite;
    transform-origin-x: 15%;
    transform-origin-y: 15%;
    transform-origin-z: initial
  }
}


.btn-accept {
  .btn-success();
  &:hover .lnr {
    animation: wrench 2.5s ease infinite;
    transform-origin-x: 90%;
    transform-origin-y: 35%;
    transform-origin-z: initial
  }
}

.btn-edit {
  .btn.btn-secondary();
  &:hover .lnr {
    animation: wrench 2.5s ease infinite;
    transform-origin-x: 90%;
    transform-origin-y: 35%;
    transform-origin-z: initial
  }
}

.btn-delete {
  .btn.btn-tertiary();
  &:hover .lnr {
    animation: burst 1s ease infinite;
  }
}

.btn-play {
  &:hover .lnr {
    animation: passing 1s ease infinite;
  }
}
