.template-media-image {
  max-width: 150px;
}

.template-media-image-cell {
  min-width: 160px;
}

#appbundle_emailTemplate_content {
  min-height: 600px;
}

pre.ace_editor {
  height: 600px;
  font-size: 15px;
}

.section-vert-margin {
  margin-top: 20px;
}

.testing-template-variables-editor {
  height: 806px !important;
}

.email-template-editor {
  height: 806px !important;
}

.ace_search_field {
  color: black;
}

.layout-blank h1 {
  font-size: 39px;
  margin-bottom: 0;
}

.email-template-form .form-group {
  margin-bottom: 0;
}

.thumbnails-fixed-height img {
  height: 200px;
  width: auto;
}

.thumbnail .caption {
  height: 65px;
}

.thumbnail .btn-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}

.thumbnail {
  position: relative;
}

.thumbnail:hover .btn-delete {
  display: block;
}

.btn-placeholder {
  display: inline-table;
  width: 25px;
  min-height: 35px;
  padding: 0 5px;
  line-height: 24px;
  background-color: #f05050;
  visibility: hidden;
}

.tile-bg-6 {
  @tile-warning-color: #f0ad4e;
  background-color: @tile-warning-color;
}

.dashboard-attachment-failed {
  left: -1px;
}

.dashboard-attachment-failed .text-danger {
  font-size: 61px;
  left: -11px;
  top: -12px;
}

.tile-description {
  font-weight: bold;
}

.tile-bg-5 {
  background-color: darken(#9ad023, 5%);
}

.tile-bg-5:hover {
  background-color: darken(#9ad023, 10%);
}

.tile-bg-6:hover {
  background-color: darken(#f0ad4e, 10%);
}

.pagination{
  padding:20px 0
}

.overflowing-container {
  padding: 30px;
}

.overflowing-content {
  display: table;
  min-width: 100%;
  margin: 0;
}

