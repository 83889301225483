.form-control{
  padding: @input-padding;
}

.form-group {

  input, textarea, select,{
    box-shadow: none;
  }

  &.has-success .form-control:focus,
  &.has-error .form-control:focus,
  &.has-warning .form-control:focus{
    box-shadow: none;
  }

  textarea{
    resize: none;
    min-height: 100px;
  }

  input:focus, textarea:focus, select:focus{
    box-shadow: none;
  }

  .radio-inline + .radio-inline,
  .checkbox-inline + .checkbox-inline,
  .checkbox-inline,
  .radio-inline{
    margin-left: 0;
    margin-right: 10px;
  }

  input[type="file"]{
    padding: 0;
    background-color: transparent;
    border: 0;
  }

}
label, input[type="checkbox"], input[type="radio"]{
  cursor: pointer;
}
label{
  font-weight: normal;
  vertical-align: middle;
  display: block;

  &.block{
    display: block;
  }
}
.form-inline{
  label{
    display: inline-block;
  }
}
input[type="radio"], input[type="checkbox"]{
  margin: 2px 0 0;
  vertical-align: middle;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label{
  color: @brand-danger;
}
.has-error .form-control{
  border-color: @brand-danger;
  box-shadow: none;
}
.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label{
  color: @brand-warning;
}
.has-warning .form-control{
  border-color: @brand-warning;
  box-shadow: none;
}
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label{
  color: @brand-success;
}
.has-success .form-control{
  border-color: @brand-success;
  box-shadow: none;
}
option{
  padding: 2px 4px;
}

.input-lg{
  border-radius: @border-radius-base;
}
.btn-group{

  .btn{
    background-color: @btn-group-bg;
    border: @btn-group-border;
    color: @btn-group-color;
    margin-right: 2px;

    &:hover{
      background-color: @btn-group-bg-hover;
      border: @btn-group-border-hover;
      color: @btn-group-color-hover;
    }
  }

  .btn.active{
    background-color: @btn-group-bg-active;
    border: @btn-group-border-active;
    color: @btn-group-color-active;
  }

  .btn-1{
    border-radius: 5px 0 0 5px;
  }

  .btn-2{
    border-radius: 0 5px 5px 0;
  }
}
.form-horizontal{

   .form-group{
     margin-left: 0;
     margin-right: 0;
   }

  .control-label {
    text-align: left;
  }
}
.form-inline{
  .select2-container.select2{
    width: auto !important;
  }
}
.has-feedback{
  position: relative;

  .fa{
    font-size: @font-size-base + 6;

    &.form-control-feedback{
      right: 2px;
      margin-top: 9px;
    }
  }

}
.file-upload{
  display: inline-block;
  overflow: hidden;
  position: relative;

  input{
    cursor: pointer;
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.file-view{
  padding: 10px 5px;

  .file{
    display: inline-block;
    margin-right: 10px;
    height: 130px;
    width: auto;

  }
}
fieldset{
  padding: 15px;
  background-color: @gray-lighter;
}


label{
  margin: 0;
}


.form-group h2{
  font-size: 20px;
  margin: 24px 0 8px;

  color: @brand-blue;
}