@keyframes wrench {
  0% {
    transform: rotate(-6deg)
  }
  8% {
    transform: rotate(6deg)
  }
  10% {
    transform: rotate(12deg)
  }
  18%, 20% {
    transform: rotate(-12deg)
  }
  28%, 30% {
    transform: rotate(12deg)
  }
  38%, 40% {
    transform: rotate(-12deg)
  }
  48%, 50% {
    transform: rotate(12deg)
  }
  58%, 60% {
    transform: rotate(-12deg)
  }
  68% {
    transform: rotate(12deg)
  }
  75% {
    transform: rotate(0deg)
  }
}

@keyframes burst {
  0% {
    opacity: 1
  }
  50% {
    transform: scale(1.8);
    opacity: 0.5
  }
  100% {
    transform: scale(1);
    opacity: 1
  }
}

@keyframes passing {

  0% {
    transform: translateX(0%);
    opacity: 1
  }
  100% {
    transform: translateX(50%);
    opacity: 0
  }

}

@keyframes pulse {
  0% {
    transform: scale(1.3)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes blur {
  0% {
    filter: blur(0px);
    transform: translateX(0px);
  }
  30% {
    filter: blur(1px);
    transform: scale(1.005)
  }
  33% {
    filter: blur(5px);
  }
  40% {
    filter: blur(2px);
  }
  60% {
    filter: blur(1px);
    transform: translateX(2px);
    transform: scale(1.020)
  }
  75% {
    filter: blur(4px);
    transform: scale(1.005)
  }
  90% {
    filter: blur(3px);
  }
  100% {
    filter: blur(0px);
    transform: translateX(0px);
  }
}

@keyframes blur-out {
  0% {
    filter: blur(2px);
  }

  100% {
    filter: blur(0px);
  }
}