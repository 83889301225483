.sidebar {

  .title {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0 1px 1px 0;
    color: #fff;
    display: none;
    font-size: 20px;
    left: 80px;
    padding: 5px;
    position: fixed;
    right: 0;
    text-align: left;
    top: 0;
    z-index: 3000;
  }
  &.sidebar-hidden {
    .nav-sidebar > li > a:hover .title {
      display: block;
    }
  }

  ul li {
    display: block;

    a.fa, a .fa, a .sidebar-ico {
      display: inline-block;
      color: @sidebar-link-color;
      font-size: 20px;
      vertical-align: middle;
      width: 25px;

      //&:hover, &:focus{
      //  background-color: @sidebar-link-hover-bg;
      //  color: @sidebar-link-hover-color;
      //}
    }

    &:after {
      border-bottom: 1px solid @sidebar-link-border-bottom;
      bottom: 0;
      content: "";
      height: 0;
      left: 4%;
      position: absolute;
      width: 92%;
    }

    a {
      color: @sidebar-link-color;
      font-size: @font-size-base;
      outline: none;
      display: block;
      vertical-align: middle;
      padding: 13px 25px 13px 20px;

      &:hover, &:focus {
        background-color: @sidebar-link-hover-bg;
        color: @sidebar-link-hover-color;
      }
      &:active {
        background-color: transparent;
      }
    }
  }

  ul > li.active {
    background-color: @sidebar-link-hover-bg;

    & > a, & > a .fa, & .sidebar-ico {
      color: @sidebar-link-hover-color;
    }

  }

  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: transparent;
    color: @sidebar-link-color;
    border-color: transparent;
  }
  .nav .open > a:focus {
    background-color: lighten(@sidebar-color-bg, 5%);
  }

  li.toggle {
    border-bottom: 1px solid darken(@sidebar-color-bg, 5%);

    &:after {
      border-bottom: 0;
      left: 0;
      width: 100%;
    }

    .sidebar-toggle {
      font-size: @font-size-base + 12;
      outline: none !important;
      color: @sidebar-toggle-color;
      line-height: 33px;

      &:hover, &:focus {
        color: darken(@sidebar-toggle-color, 10%);
      }

      .toggle-title {
        font-size: @font-size-base + 6;
        text-transform: uppercase;
      }

      .fa, .sidebar-ico {
        font-size: 20px;
      }
    }
  }

  li.title {
    padding: 15px 30px;
    border-bottom: 1px solid @gray-dark;
    text-transform: uppercase;
    font-size: @font-size-base + 4;
    color: darken(@gray-darker, -8%);
    font-weight: 400;

    &:after {
      border-bottom: 0;
    }
  }

  li.has-children {
    position: relative;

    &:after {
      border-bottom: none;
      content: "\f107";
      font-family: fontawesome;
      position: absolute;
      right: 11px;
      top: -2px;
      left: 0;
      bottom: 0;
      margin: 15px 0 auto auto;
      width: 10px;
      height: 17px;
      color: @sidebar-link-color;
    }

    &:before {
      border-bottom: 1px solid @sidebar-link-border-bottom;
      bottom: 0;
      content: "";
      height: 0;
      left: 4%;
      position: absolute;
      width: 92%;
    }

    & > ul {
      display: none;
      list-style-type: none;
      position: relative;
      background-color: @sidebar-color-bg;
      margin: 0 0 0;
      padding: 0;

      li {

        a {
          padding: 6px 0 6px 49px;

          &:after {
            display: none;
          }

          &:hover {
            text-decoration: none;
            color: @sidebar-link-hover-color;
          }
        }

        &:after {
          border-bottom: 0;
        }
      }
    }

    &.open ul {
      display: block;
    }
  }

  &.sidebar-hidden {
    width: 80px;

    .nav {
      width: 80px;
      position: absolute;
    }

    .sidebar-label {
      display: table-column;
      opacity: 0;
    }
    ///*BACKWARD COMPLATIBILITY/*
    .closing {
      display: table-column;
      opacity: 0;
    }
    ///*
    ul > li {
      text-align: center;

      a {
        padding: 13px 20px 13px 15px;
      }
    }

    ul > li > a.fa,
    ul > li > a .sidebar-ico,
    ul > li > a .fa {
      margin-right: 0;
      margin-left: 0;
    }

    li.has-children {

      &:after {
        content: "\f105";
        right: 0;
      }
    }

    li.has-children.open ul {
      list-style-type: none;
      position: absolute;
      top: 0;
      left: 100%;

      padding: 0;
      width: 240px;

      li {
        text-align: left;
        a {
          padding: 5px 5px 5px 10px;
        }
      }
    }

  }
}

.sidebar-label {
  opacity: 1;
  transition: opacity 300ms ease 0s;
}

.sidebar-logo-md {
  display: none;
}

@media (max-width: @screen-sm-max) {
  .sidebar-logo-md {
    display: block;
    display: inline;
    text-align: center;
  }

  .sidebar-logo {
    display: none;
  }
}

@media (max-width: @screen-sm-min) {
  .sidebar-logo-md {
    display: block;
    display: inline;
    text-align: center;
    width: 26px;
  }
}